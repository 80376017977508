/* Common imports */

/* mui imports */
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, useTheme } from "@mui/material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/thunks";
/* redux imports */

/* Stylings */

const PropertyStatusComponent = ({
	search_id,
	search_filter_state,
	dispatch,
	propertyType,
	transactionType,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["property_status"];
	dispatch: Function;
	propertyType: string | undefined;
	transactionType: string;
}) => {
	const theme = useTheme();

	let Propertystatus =
		transactionType === "rent"
			? [
					{
						id: "immediately",
						title: "Immediately",
						value: "immediately",
					},
					// {
					// 	id: "select_date",
					// 	title: "Select Date",
					// 	value: "select_date",
					// },
				]
			: propertyType === "plot" || propertyType === "plot_commercial" || propertyType === "farm_plot"
				? [
						{
							id: "ready_for_registration",
							title: "Ready for Registration",
							value: "ready_for_registration",
						},
						{
							id: "under_construction",
							title: "Under Construction",
							value: "under_construction",
						},
					]
				: [
						{
							id: "ready_to_move_in",
							title: "Ready to Move In",
							value: "ready_to_move_in",
						},
						{
							id: "under_construction",
							title: "Under Construction",
							value: "under_construction",
						},
					];

	const handleUpdatePropertyStatus = (value: Array<SearchFilterOption>) => {
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: true }));
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: value,
			}),
		);
	};

	// React.useEffect(() => {
	// 	if (search_filter_state.selected_options.length === 0) {
	// 		Propertystatus = [];
	// 	}
	// }, [search_filter_state]);

	return (
		<Stack
			direction="row"
			spacing={3}
			// marginBottom={2}
		>
			<FormControl
				sx={{
					[theme.breakpoints.down("xsPlus")]: { paddingLeft: "1rem" },
				}}
			>
				<FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
				<RadioGroup
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
					sx={{ gap: "0.5rem", fontSize: "1rem", fontWeight: 400 }}
				>
					{Propertystatus.map((data) => (
						<FormControlLabel
							id={"home_desktop_filters_property_status_" + data.id}
							key={data.id}
							value={data.value}
							control={
								<Radio
									size="medium"
									checked={search_filter_state?.selected_options[0]?.id === data.id}
								/>
							}
							label={data.title}
							onChange={() => {
								search_filter_state?.selected_options[0]?.id === data.id
									? handleUpdatePropertyStatus([] as Array<SearchFilterOption>)
									: handleUpdatePropertyStatus([data] as Array<SearchFilterOption>);
							}}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</Stack>
	);
};
export default PropertyStatusComponent;
